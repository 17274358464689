/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { GraphCms_SectorPage, Query } from "../../../generated/graphql-types";
import {
  Hero,
  Statement,
  Article,
  Quote,
  StatisticDetails,
  Dots,
  I18Link,
} from "../../../components";
import { Infographic } from "../../../components/Infographic";
import { buildMenu, Layout } from "../../../layouts";
import leftIcon from "../../../images/Left Button.svg";
import { getCleanedRTF, rtfFromText } from "@bond-london/graphcms-rich-text";
import { getAlt, getImage } from "@bond-london/gatsby-graphcms-components";

function getLinkForSectorPage(sectorPage: GraphCms_SectorPage) {
  return `/${sectorPage.sectorType.toLowerCase()}/${sectorPage.slug}`;
}

const component: React.FC<PageProps<Pick<Query, "graphCmsCaseStudy">>> = ({
  path,
  data: { graphCmsCaseStudy: caseStudy },
}) => {
  if (!caseStudy) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(caseStudy.menu)}
      footerMenu={buildMenu(caseStudy.footerMenu)}
      backgroundColour="White1"
      keywords={caseStudy.keywords}
      title={caseStudy.title || "Case study"}
      description={caseStudy.description}
    >
      <Hero
        subheading={caseStudy.caseStudyPageHeading ?? caseStudy.headline}
        title="Case Study"
        image={getImage(caseStudy.heroImage)}
        short={true}
        colour={caseStudy.heroColour}
        showSearch={true}
        isCasestudy={"true"}
      />
      {caseStudy.headline && (
        <Statement
          id={caseStudy.id + "-headline"}
          cleanedMessage={rtfFromText(caseStudy.headline)}
          rtfClassNameOverrides={{ p: "h8" }}
          rtfClassName="z-40"
        >
          <div className="absolute h-full w-full">
            <Dots
              type="LightBlueLeftRight"
              className="w-3/10 left-7/10 top-1/12 z-30"
              inFront={true}
            />
          </div>
        </Statement>
      )}
      {caseStudy.businessChallenge && (
        <Statement
          id={caseStudy.id + "-bizchal"}
          title={caseStudy.businessChallenge === "" ? "" : "Business challenge"}
          cleanedMessage={rtfFromText(caseStudy.businessChallenge)}
          className="bg-gradient-to-b from-white-1 via-light-blue to-white-1 py-160px"
        />
      )}
      <Article
        side="right"
        id={caseStudy.id + "-context"}
        image={getImage(caseStudy.contextImage)}
        alt={getAlt(caseStudy.contextImage, "Context")}
        detailsRTF={getCleanedRTF(caseStudy.context)}
      />
      {(caseStudy.businessImpactStatistics || caseStudy.businessImpact) && (
        <Infographic
          className="bg-gradient-to-b from-white-1  via-light-blue to-white-1 "
          title="Business impact"
          headlineRTF={
            caseStudy.businessImpact
              ? rtfFromText(caseStudy.businessImpact)
              : undefined
          }
          statistics={caseStudy.businessImpactStatistics as StatisticDetails[]}
        >
          <div className="absolute w-full h-full">
            <Dots
              type="White"
              className="w-4/10 left-3/10 top-4/10"
              inFront={true}
            />
          </div>
        </Infographic>
      )}
      <Article
        id={caseStudy.id + "-sln"}
        image={getImage(caseStudy.solutionImage)}
        alt={getAlt(caseStudy.solutionImage, "Solution")}
        title={
          caseStudy.topLineSolution?.text !== undefined
            ? "Top Line Solution"
            : ""
        }
        detailsRTF={getCleanedRTF(caseStudy.topLineSolution)}
      />
      <Quote
        quote={caseStudy.clientsQuote}
        author={caseStudy.clientQuoteFrom}
        className="bg-light-grey z-10"
      >
        <Dots
          type="White"
          className="col-start-11 col-span-4 transform-gpu -translate-y-72px translate-x-1/12"
          inFront={false}
        />
      </Quote>
      {caseStudy.keyTakeaway?.cleaned && (
        <Statement
          id={caseStudy.id + "-takeway"}
          title="Key Takeaway"
          message={caseStudy.keyTakeaway}
        />
      )}
      {caseStudy.primarySector && (
        <section className="py-section page-grid max-width z-10 gap-y-20px">
          <I18Link
            to={
              caseStudy.primarySector.slug == "wiredup"
                ? "/wiredup-change-management-software"
                : getLinkForSectorPage(caseStudy.primarySector)
            }
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            {caseStudy.primarySector.sector}
          </I18Link>
          {caseStudy.secondarySector && (
            <I18Link
              to={
                caseStudy.primarySector.slug == "wiredup"
                  ? "/en/wiredup-change-management-software"
                  : getLinkForSectorPage(caseStudy.secondarySector)
              }
              className="flex items-center col-start-2 col-span-12 md:col-start-8 md:col-span-6"
            >
              <img
                src={leftIcon}
                alt="Left"
                className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
              />
              {caseStudy.secondarySector.sector}
            </I18Link>
          )}
        </section>
      )}
    </Layout>
  );
};

export default component;

export const caseStudyQuery = graphql`
  query CaseStudyQuery($id: String) {
    graphCmsCaseStudy(id: { eq: $id }) {
      id
      menu {
        ...MenuFragment
      }
      additionalContext {
        cleaned
      }
      businessChallenge
      businessChallengeImage {
        ...ImageFragment
      }
      businessImpact
      businessImpactStatistics
      client
      clientQuoteFrom
      clientsQuote
      context {
        cleaned
      }
      contextImage {
        ...ImageFragment
      }
      headline
      caseStudyPageHeading
      heroImage {
        ...ImageFragment
      }
      heroColour
      keyTakeaway {
        cleaned
      }
      keyTakeawayImage {
        ...ImageFragment
      }
      primarySector {
        ...SectorPageLinkFragment
      }
      secondarySector {
        ...SectorPageLinkFragment
      }
      service
      solutionImage {
        ...ImageFragment
      }
      topLineSolution {
        cleaned
      }
      keywords
      description
    }
  }
`;
